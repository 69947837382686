import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.045 * cardRef.current.clientWidth));
        setLetterSpacing(0.002 * cardRef.current.clientWidth);
        setLineHeight(0.06 * cardRef.current.clientWidth);
        setMarginLeft(0.4 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>Stribor, I'm sorry,</p>
        <p>I forgot your face.</p>
        <p>You did everything right</p>
        <p>and convinced my friends.</p>
        <p>All written,</p>
        <p>well placed:</p>
        <p>That women act as they wish,</p>
        <p>That activism does not exist.</p>
        <p>He missed too close,</p>
        <p>looked into our eyes.</p>
        <p>Did not spare us the solitude.</p>
        <p>Out of the fucking embers!</p>
        <p>All the ways around the world</p>
        <p>he trampled past, proper.</p>
        <p>You are volvo, marble, investment,</p>
        <p>I am sufficiently smart</p>
        <p>and sufficiently dumb.</p>
        <p>I play dead.</p>
      </div>
    </div>
  );
}
