import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.033 * cardRef.current.clientWidth));
        setLineHeight(0.055 * cardRef.current.clientWidth);
        setLetterSpacing(0.002 * cardRef.current.clientWidth);
        setMarginRight(-0.03 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
          letterSpacing: `${letterSpacing}px`,
        }}
      >
        <p>In the evening, when they start to wander,</p>
        <p>I try and think of unattainable surrender,</p>
        <p>They stop and take a long deep breath,</p>
        <p>The length of silence being intercept,</p>
        <p>Only to continue as if I said something</p>
        <p>That a family member took as nothing.</p>
        <p>Maybe the next after next cigarette</p>
        <p>Will knock them out for a bit longer,</p>
        <p>(People do know you're a brunette!)</p>
        <p>But the throat stings already somber.</p>
        <p>It's a next day's problem, I repeat,</p>
        <p>But this day doesn't send his greets.</p>
        <p>Stop it, Stop it, Shush, Calm yourself!</p>
        <p>You are going to put this on a shelf</p>
        <p>And when sleeping, noise comes through,</p>
        <p>You'll find the monster is forever in you.</p>
      </div>
    </div>
  );
}
