import { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import cards, {
  getCardFromIndex,
  getCardFromCardPath,
} from "../../cards/cards";
import Credits from "../../components/Credits/Credits";
import HeadTags from "../../components/HeadTags/HeadTags";
import HoverCard from "../../components/HoverCard/HoverCard";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Map from "../../components/Map/Map.jsx";
import MapCard from "../../components/MapCard/MapCard";
import imagesForPreload from "../../helpers/imagesForPreload";
import styles from "./Home.module.scss";

export default function Home() {
  const history = useHistory();
  const { cardName } = useParams<{ cardName?: string }>();

  const [isCardShown, setIsCardShown] = useState(false);
  const [markerClicked, setMarkerClicked] = useState<number | null>(null);

  const [isHoverCardShown, setIsHoverCardShown] = useState(false);
  const [markerHovered, setMarkerHovered] = useState<number | null>(null);
  const [markerCoords, setMarkerCoords] = useState<{
    x: number;
    y: number;
  } | null>(null);

  // map layer fires a load event when all images are loaded, we set this to true then
  const [hasMapLoaded, setHasMapLoaded] = useState(false);
  // we prefetch some tiles to put them in browser's cache, we set this to true when all images are fetched
  const [areImagesPreloaded, setAreImagesPreloaded] = useState(false);

  function preloadImage(url: string) {
    return new Promise(function (resolve, reject) {
      const img = new Image();
      img.onload = function () {
        resolve(url);
      };
      img.onerror = function () {
        reject(url);
      };
      img.src = url;
    });
  }

  const preloadImages = useCallback(async () => {
    await Promise.all(imagesForPreload.map((el) => preloadImage(el)));
    setAreImagesPreloaded(true);
  }, []);

  useEffect(() => {
    function handleContextMenu(event: { preventDefault: () => void }) {
      event.preventDefault();
    }

    // disable right-click context menu
    document.addEventListener("contextmenu", handleContextMenu);

    preloadImages();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleContextMenu);
  }, [preloadImages]);

  useEffect(() => {
    // A card can be shown in two ways:
    // 1. By clicking the marker on the map.
    //    In this case, the click will cause the path to update to some value. (look at the marker click handler function below)
    // 2. By landing on the website with the proper `cardName` in the path, e.g. `/about-fire`
    // In both cases, in this useEffect, we will use the path value to select and display the proper card.
    // If the path is invalid (no card exists for that path), we redirect to the index page

    if (cardName) {
      const card = getCardFromCardPath(cardName);
      // if the path corresponds to an existing card
      if (card !== undefined) {
        // hide the hover card, if shown
        setIsHoverCardShown(false);
        // the index is used to get the data for the matching card
        setMarkerClicked(card.index);
        // show the card
        setIsCardShown(true);
      } else {
        // if the path does not correspond to an existing card, we redirect to index page
        history.push("/");
      }
    }
  }, [cardName, history]);

  function handleMarkerClick(index: number) {
    // When a user clicks on the map marker, we pass the index through this callback
    // We use the index to match it to the correct cards, and update the path
    // Then, the useEffect above takes care of displaying the card
    const card = getCardFromIndex(index);
    if (card) {
      history.push(`/${card.path}`);
    }
  }

  function handleMapCardClose() {
    // Reset the state to default, hide the card, and redirect to the index page
    setMarkerClicked(null);
    setIsCardShown(false);
    history.push("/");
  }

  function handleMarkerMouseOver(
    index: number,
    markerCoordinates: { x: number; y: number }
  ) {
    // Only show the hover card if there is no card displayed at the moment
    if (!isCardShown) {
      // if we accidentaly have a marker rendered on map with no corresponding card/data
      if (!cards[index]) {
        return;
      }
      // We need the coordinates so we know where to place the hover card in the viewport
      setMarkerCoords(markerCoordinates);
      setMarkerHovered(index);
      setIsHoverCardShown(true);
    }
  }

  function handleMarkerMouseOut() {
    // Reset the state to default and hide the hover card
    setMarkerHovered(null);
    setIsHoverCardShown(false);
  }

  return (
    <div className={styles.wrapper}>
      {!hasMapLoaded ? <LoadingScreen /> : null}
      {hasMapLoaded ? <Credits /> : null}
      {areImagesPreloaded && (
        <Map
          onMarkerClick={handleMarkerClick}
          onMarkerMouseOver={handleMarkerMouseOver}
          onMarkerMouseOut={handleMarkerMouseOut}
          onLoad={() => setHasMapLoaded(true)}
        />
      )}
      {isCardShown && markerClicked !== null ? (
        <>
          <MapCard
            onClose={handleMapCardClose}
            cardFaces={cards[markerClicked].cardFaces}
            backgroundColor={cards[markerClicked].backgroundColor}
          />
          <HeadTags
            title={cards[markerClicked].title}
            pageUrl={cards[markerClicked].pageUrl}
            imageUrl={cards[markerClicked].imageUrl}
          />
        </>
      ) : (
        <HeadTags
          title={"Mapping the Territory"}
          pageUrl={"https://mppttrr.com"}
          imageUrl={"https://mppttrr.com/images/nista6a.png"}
        />
      )}
      {isHoverCardShown && markerCoords !== null && markerHovered !== null && (
        <HoverCard
          markerCoordinates={markerCoords}
          cardFace={cards[markerHovered].hoverFace}
        />
      )}
    </div>
  );
}
