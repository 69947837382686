import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.027 * cardRef.current.clientWidth));
        setLetterSpacing(0.002 * cardRef.current.clientWidth);
        setLineHeight(0.07 * cardRef.current.clientWidth);
        setMarginRight(0.37 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
        }}
      >
        <p>za tri očajna dana</p>
        <p>verbalna gimnastika</p>
        <p>šesnaest puta cvala</p>
        <p>kupovna i plastična</p>
        <p>kome treba soli</p>
        <p>zovite me odmah</p>
        <p>odjevena molim</p>
        <p>samo jedan zamak</p>
        <p>mogu dati sve</p>
        <p>jer imam tako malo</p>
        <p>oprostit ćete</p>
        <p>i reći Budalo</p>
      </div>
    </div>
  );
}
