import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.035 * cardRef.current.clientWidth));
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setLineHeight(0.048 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          textAlign: "center",
        }}
      >
        <p>Lacunae said I am fertile,</p>
        <p>Like a stone less limon soil,</p>
        <p>Silting my hopes to ripple,</p>
        <p>Of 4 minutes I can't recall.</p>
        <p>Is it a void, a pain, a thing missing,</p>
        <p>A muddy splash of underwater clay,</p>
        <p>Or space where there was something</p>
        <p>That continues in piercing decay.</p>
        <p>It is unclear, still, an urgent matter,</p>
        <p>Of a spilled clutter, my favorite sir.</p>
        <br />
        <p>Lacunae begs me to stop the math,</p>
        <p>In the gap of Nothing short of Of course.</p>
        <p>There is a reason why we hugged the raft</p>
        <p>On our weathered trip of stillness indoors.</p>
        <p>What if it's the same old tale,</p>
        <p>Repeating itself till endless frays,</p>
        <p>I forever felt the underwater veil</p>
        <p>But besides feeling, I have no say.</p>
        <p>Unexplainable circling format,</p>
        <p>Decay executed by life.</p>
        <p>A gray amnesia in tritone,</p>
        <p>Mercilessly keeping me alive.</p>
      </div>
    </div>
  );
}
