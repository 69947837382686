export default function HoverFace() {
  return (
    <img
      src={
        process.env.PUBLIC_URL +
        "/images/45FallacyofmisplacedconcretenessHover.jpg"
      }
      alt=""
    />
  );
}
