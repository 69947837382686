import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.035 * cardRef.current.clientWidth));
        setLetterSpacing(0.001 * cardRef.current.clientWidth);
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setMarginLeft(0.4 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>U kraju noći studene</p>
        <p>odbacilo je lišće.</p>
        <p>Kao kosa izdajica,</p>
        <p>nestaje u odvodu.</p>
        <p>Živi tamo svoju slobodu.</p>
        <p>Kako tako preko noći</p>
        <p>perje tvoje ima pobjeći?</p>
        <p>Kako tako kao kaki</p>
        <p>ne nalaziš ležaj meki?</p>
        <p>Od nekud, iz daleka, šum.</p>
        <p>Koliko još vremena do jutra?</p>
        <p>Kasnim, ne hodam, uzalud.</p>
        <p>Hladno mi je,</p>
        <p>Nisam mudra.</p>
        <p>Bez stanke prošli dan i noć tud.</p>
        <p>Dobro jutro, hrano bogova,</p>
        <p>Adstringentna moja situacija!</p>
        <p>Oprosti što ne prija,</p>
        <p>i što ne voli te okolina.</p>
      </div>
    </div>
  );
}
