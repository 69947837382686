import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);
  const [paddingLeftAndRight, setPaddingLeftAndRight] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.033 * cardRef.current.clientWidth));
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setLetterSpacing(0.001 * cardRef.current.clientWidth);
        setMarginTop(0.03 * cardRef.current.clientWidth);
        setPaddingLeftAndRight(0.1 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginTop: `${marginTop}px`,
          letterSpacing: `${letterSpacing}px`,
          padding: `0 ${paddingLeftAndRight}px`,
          textAlign: "justify",
        }}
      >
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;Bivalo bi uvijek kad je ljeto završavalo, da
          bi mi se leđa ljepila na zid vodovoda. Podne, ponoć, potpuno nesvjesna
          razlike, nikada nije bilo razlike, kad je počelo, ne javljanje. Tamo
          bih lizala rane, i svi krpelji me izbjegavali.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;To je isti osjećaj koji nikada nije nestao
          niti se produbio u vrlinu da bi od mene napravio dobru curu: fali mi
          moje doma zauvijek, doma ljubavi i ljubavi prijatelja, zauvijek
          spremnih na sve moje pogreške. Fali mi moj dom zauvijek jer me njegova
          nepodnošljiva tišina čini da izgledam kao ona divlja, iako sam zapravo
          samo gladna. Glupa čudakinja zamaskirana u dobar razmjer struka i
          bokova. Fali mi moj dom i kad sam doma, prošlost je prošla i sve moje
          uspomene postaju previše puste. Ne želim biti jedini lik u njima, ne
          želim da su samo o meni, ali ne mogu se sjetiti ničega, tog bića kad
          je počelo, i sad kad završava. Nedostaje mi dom i popunjavam sva
          prazna mjesta sa sobom u pretpostavljenom domu i plačem svaki dan
          svugdje s velikom prehladom što snažno grebe na dnu mojih pluća, na
          kraju mojih leđa. Nikada neću stići doma. Je li nepodnošljivo teško
          čuti to toliko puta? Svi mi govore da je još uvijek tamo ali ja znam,
          sto postotno znam, jer nisam srela nikog koga poznajem, svaki puta
          kada sam pokušala stići doma.
        </p>
      </div>
    </div>
  );
}
