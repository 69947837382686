import styles from "./HoverCard.module.scss";

interface HoverCardProps {
  cardFace: JSX.Element;
  markerCoordinates: { x: number; y: number };
}

export default function HoverCard(props: HoverCardProps) {
  return (
    <div
      className={styles.hoverCard}
      style={{
        top: `${props.markerCoordinates.y - 80}px`,
        left: `${props.markerCoordinates.x}px`,
      }}
    >
      {props.cardFace}
    </div>
  );
}
