import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.025 * cardRef.current.clientWidth));
        setLetterSpacing(0.004 * cardRef.current.clientWidth);
        setLineHeight(0.04 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          textAlign: "center",
        }}
      >
        <p>it's deleting all of my print screens</p>
        <p>throwing away the feathers, straws, post-its.</p>
        <p>rings, beer, taped, and bought</p>
        <p>glass, mostly bloodless, shot</p>
        <p>rocks, papers, razor blades</p>
        <p>even books, oh my hades</p>
        <p>cds, vinyls, concert tickets</p>
        <p>broken links, stolen exhibits.</p>
        <p>obscure socials, numbers, letters</p>
        <p>potions, drumstick, to get better</p>
        <p>pieces of wood, nails to coffins</p>
        <p>jars with hearts without batteries</p>
        <p>(but you Guard it)</p>
        <p>dead flowers, hard hit.</p>
        <p>all the perfume bottles</p>
        <p>Magnifique also</p>
        <p>chains, spit, figurines</p>
        <p>photos, the bit that convenes</p>
        <p>cards, decks, arrows, dice</p>
        <p>the sadness in my own eyes</p>
        <p>dictionaries of your words</p>
        <p>maps, routes of our worlds</p>
        <p>everything you have given me</p>
        <p>mostly pretend and unknowingly</p>
        <p>i am discarding all the oaths</p>
        <p>keeping me and the clothes.</p>
      </div>
    </div>
  );
}
