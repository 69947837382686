import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./pages/home/Home";
import ScreenshotHome from "./pages/screenshotHome/ScreenshotHome";

export default function App() {
  return (
    <Router>
      <Switch>
        {process.env.NODE_ENV === "development" && (
          <Route exact path={"/screenshot/:cardName/:cardNumber"}>
            <ScreenshotHome />
          </Route>
        )}
        <Route exact path={"/:cardName?"}>
          <Home />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}
