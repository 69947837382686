import { Helmet } from "react-helmet";

interface HeadTagsProps {
  title: string;
  pageUrl: string;
  imageUrl: string;
}

export default function HeadTags(props: HeadTagsProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta property="og:site_name" content="Mapping the Territory" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${props.title}`} />
      <meta property="og:image" content={`${props.imageUrl}`} />
      <meta property="og:url" content={`${props.pageUrl}`} />
      <link rel="canonical" href={`${props.pageUrl}`} />
    </Helmet>
  );
}
