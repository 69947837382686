import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.035 * cardRef.current.clientWidth));
        setLetterSpacing(0.004 * cardRef.current.clientWidth);
        setLineHeight(0.07 * cardRef.current.clientWidth);
        setMarginLeft(0.57 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>Darling wants an explanation.</p>
        <br />
        <p>She doesn't believe in magic.</p>
        <p>Can't read the language,</p>
        <p>Does not want to learn.</p>
        <p>Darling wants answers,</p>
        <p>But has no questions.</p>
        <p>Eager to be by your side,</p>
        <p>Will flower in the attention.</p>
        <p>Don't you dare to mention</p>
        <p>She's a bit crazy for her age!</p>
        <p>But you do hate pretention</p>
        <p>And girls being teenage.</p>
        <p>Well only sometimes.</p>
        <p>When darling whines.</p>
      </div>
    </div>
  );
}
