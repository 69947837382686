/* eslint-disable jsx-a11y/anchor-has-content */

import styles from "./ScaleLink.module.scss";

export default function ScaleLink() {
  return (
    <a
      className={styles.wrapper}
      href="https://www.miamiletic.com/"
      target="_blank"
      rel="noreferrer"
    >
      <img
        className={styles.image}
        src={`${process.env.PUBLIC_URL}/images/Mjerilo01.png`}
        alt=""
      />
    </a>
  );
}
