import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.037 * cardRef.current.clientWidth));
        setLineHeight(0.057 * cardRef.current.clientWidth);
        setMarginTop(0.02 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginTop: `${marginTop}px`,
        }}
      >
        <p>Listen,</p>
        <p>There is a fire.</p>
        <br></br>
        <p>From all the shite being thrown into it,</p>
        <p>The most soluble was a burning desire.</p>
        <p>Turned to cinders once were pliers,</p>
        <p>Dresses never mended by the will of a child.</p>
        <p>The screams already formed a choir</p>
        <p>sturdy, quaking, singing songs of liar.</p>
        <br></br>
        <p>Fire does not know of time passing</p>
        <p>makes no difference in losing or retracing</p>
        <p>And still, </p>
        <p>there somewhere,</p>
        <p>is a fire.</p>
        <br></br>
        <p>So for a change, dare not to dream</p>
        <p>Any more of what can’t be admired.</p>
        <p>Acquired,</p>
        <p>let go</p>
        <p>and smile.</p>
      </div>
    </div>
  );
}
