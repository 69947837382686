import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.04 * cardRef.current.clientWidth));
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setLineHeight(0.065 * cardRef.current.clientWidth);
        setMarginLeft(0.365 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>činilo se kao:</p>
        <p>da kretala sam se čučeći</p>
        <p>dolje na staroj ex-pješčanoj plaži</p>
        <p>med sivkaste stijene i ponekad truleži</p>
        <p>pod plimom izguranim zimzelenim</p>
        <p>u bijelim potpeticama s crvenim jezičcima</p>
        <p>kosa mi je opet bila plava</p>
        <p>velika i puna poput maslačka</p>
        <p>možda sam bila tanko nezdrava?</p>
        <p>u tilu od konfeta</p>
        <p>iz vitrine s porculanom</p>
        <p>od policije sam se šuljala</p>
        <p>jer sam sobom okolinu užgala</p>
        <p>svim lešinama održala bis</p>
        <p>prije vremena poznatih pinna nobilis</p>
      </div>
    </div>
  );
}
