import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.035 * cardRef.current.clientWidth));
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setLineHeight(0.045 * cardRef.current.clientWidth);
        setMarginLeft(0 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          textAlign: "center",
        }}
      >
        <p>Nešto hoće da budim se u 4.</p>
        <p>Da se vratim mirisu težine,</p>
        <p>smetnji i metnji ritmičnoj,</p>
        <p>jutarnjoj vodi, flekama s rijeke.</p>
        <p>Tankom prozoru što me dijeli</p>
        <p>krnje i krhko od zime,</p>
        <p>koja počinje prije jabuka,</p>
        <p>i zapravo ne postoji više.</p>
        <p>Snijegu iz parka,</p>
        <p>jureći za simpatijama.</p>
        <p>Imena mom grlu nepoznata.</p>
        <p>Treski bubrega u vremenu bez otpora,</p>
        <p>da kilometre ne poništavamo biciklama.</p>
        <p>Kad dođe do potopa,</p>
        <p>preko najstarijeg mosta, do juga,</p>
        <p>gdje stane samo jedan</p>
        <p>uz zidić, dok ljubi i peca.</p>
        <p>Tri puta pogodi me staklom.</p>
        <p>Nije kao godine videa i pjesama,</p>
        <p>na trgu sama je tiha mahovina.</p>
        <p>Čekali smo da prođe i prošlo je sve,</p>
        <p>samo vonj urina potpetici ostaje.</p>
        <p>Daleko je ljepše, moje selo malo.</p>
        <p>Samo u snu do tog silta,</p>
        <p>plodnoj zemlji bez kamena.</p>
      </div>
    </div>
  );
}
