import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.03 * cardRef.current.clientWidth));
        setLineHeight(0.09 * cardRef.current.clientWidth);
        setMarginTop(-0.2 * cardRef.current.clientWidth);
        setMarginRight(-0.07 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginTop: `${marginTop}px`,
          marginRight: `${marginRight}px`,
        }}
      >
        <p>it’s like that yes</p>
        <p>Losing the weight and losing the butt.</p>
        <p>hair falling out, but you can’t deflate the bone…</p>
        <p>Or can you?</p>
        <br></br>
        <p>I volunteer discreetly</p>
        <p>My skeleton never befriended my skull completely.</p>
      </div>
    </div>
  );
}
