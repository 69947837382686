import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.045 * cardRef.current.clientWidth));
        setLetterSpacing(0.002 * cardRef.current.clientWidth);
        setLineHeight(0.06 * cardRef.current.clientWidth);
        setMarginLeft(0.4 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>Stribore, žao mi je,</p>
        <p>Zaboravila sam tvoje lice.</p>
        <p>Napravio si sve kako treba</p>
        <p>i uvjerio moje prijateljice.</p>
        <p>Sve napisano,</p>
        <p>dobro postavljeno:</p>
        <p>Da žene glume kako žele,</p>
        <p>Da aktivizam ne postoji.</p>
        <p>Promaknuo se pre blizu,</p>
        <p>gledao nas u oči.</p>
        <p>Ne štedio nas samoće.</p>
        <p>Iz jebene žeravice!</p>
        <p>Svim putevima oko svijeta</p>
        <p>prolazio topotom štabel.</p>
        <p>Ti si volvo, mramor, ulaganje,</p>
        <p>Ja sam dovoljno pametna</p>
        <p>i dovoljno glupa.</p>
        <p>Pravim se mrtva.</p>
      </div>
    </div>
  );
}
