import Bowser from "bowser";
import { motion, useMotionTemplate } from "framer-motion";

import styles from "./CardShine.module.scss";

interface CardShineProps {
  posX: any;
  posY: any;
  opacity: any;
  shineHyp: any;
}

export default function CardShine(props: CardShineProps) {
  const backgroundPositionFirstLayer = useMotionTemplate`center, ${props.posX}% ${props.posY}%, center`;

  const opacity = useMotionTemplate`${props.opacity}`;

  const filter = useMotionTemplate`brightness(${props.shineHyp}) contrast(3) saturate(0.66)`;

  const browser = Bowser.getParser(window.navigator.userAgent);

  // the shine feature does not look right on Safari
  // disable for now until a solution is found
  if (browser.getBrowserName() === "Safari") {
    return null;
  }

  return (
    <>
      <motion.div
        style={{
          backgroundPosition: backgroundPositionFirstLayer,
          filter,
          opacity,
        }}
        className={styles.cardShineFirstLayer}
      />
      <motion.div
        style={{
          filter,
          opacity,
        }}
        className={styles.cardShineSecondLayer}
      />
    </>
  );
}
