import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.037 * cardRef.current.clientWidth));
        setLineHeight(0.06 * cardRef.current.clientWidth);
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setMarginRight(-0.5 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
          letterSpacing: `${letterSpacing}px`,
          width: "100%",
        }}
      >
        <p>zdravo princezo</p>
        <p>gdje se odmaraš ti?</p>
        <p>ako nađu tvoje mjesto za ležanje</p>
        <p>u 90 godina što si spavala sama</p>
        <p>u jarku, spaljena na skroz</p>
        <p>uz svojega brata</p>
        <p>hoće li to imati smisla?</p>
        <p>svi mistici svijeta</p>
        <p>su do sada ispali</p>
        <p>samo ljudi</p>
        <p>ako su ikada bili ljubavnici ili prijatelji</p>
        <p>ništa je koliko vrijede.</p>
        <p>oni su znali za tvoje korzete</p>
        <p>punjene draguljima!</p>
        <p>i ako razotkriju</p>
        <p>kukavice od te revolucije</p>
        <p>zločin smrti uvijek će biti</p>
        <p>manji od zločina života.</p>
      </div>
    </div>
  );
}
