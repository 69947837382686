import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.032 * cardRef.current.clientWidth));
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setLetterSpacing(0.004 * cardRef.current.clientWidth);
        setMarginTop(0.0 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginTop: `${marginTop}px`,
          letterSpacing: `${letterSpacing}px`,
        }}
      >
        <p>Voda nam sa stropa kaplje na banjak</p>
        <p>mliječne boje razrijeđene čistkama</p>
        <p>sve do poda da nabije tempo</p>
        <p>maman, neočistivo je, i čeka.</p>
        <p>Voda mi sa stropa kaplje na banjak,</p>
        <p>malo me pošprica po sredini kičme,</p>
        <p>okrenem se od sunca i ne vidim ništa</p>
        <p>samo prozor na čelu, do terase na jugu.</p>
        <p>U snu je to naš problem,</p>
        <p>nama je nad glavom korijen.</p>
        <p>Jer nisu ni krpe kao nekoć,</p>
        <p>brišeš, žmikaš, do ponoć</p>
        <p>a prokleta voda samo odbija se</p>
        <p>od kose koja štopa sve.</p>
        <p>Ljeti mi pomazi madeže,</p>
        <p>spoji lice natrag na nulu.</p>
        <p>Jest da smo krš, i voda je duboko</p>
        <p>ali od nekud je i to zmajevo oko.</p>
        <p>Kao što je susjeda nestala,</p>
        <p>Stara kuća se komoć raspala.</p>
        <p>Tražila sam, ali nigdje broda,</p>
        <p>Od nas ostala je samo voda.</p>
      </div>
    </div>
  );
}
