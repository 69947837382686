import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.04 * cardRef.current.clientWidth));
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setLineHeight(0.065 * cardRef.current.clientWidth);
        setMarginLeft(0.365 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>it felt like:</p>
        <p>i was squatingly moving</p>
        <p>down on the old ex-sandy beach</p>
        <p>grayish rocks and sometimes rot</p>
        <p>underneath the tide ridden evergreens</p>
        <p>in white kitten heels with red tongues</p>
        <p>my hair was once again blonde</p>
        <p>big and full like dandelion</p>
        <p>maybe i was skinny fed?</p>
        <p>in the tulle confetti</p>
        <p>from the china closet</p>
        <p>i was sneaking from the cops</p>
        <p>for being smoking hot</p>
        <p>throwing a show for all the carcasses</p>
        <p>of before time known pinna nobilis</p>
      </div>
    </div>
  );
}
