import Card1Face1 from "../components/CardFaces/Card1/Face1";
import Card1Face2 from "../components/CardFaces/Card1/Face2";
import Card1HoverFace from "../components/CardFaces/Card1/HoverFace";
import Card2Face1 from "../components/CardFaces/Card2/Face1";
import Card2Face2 from "../components/CardFaces/Card2/Face2";
import Card2HoverFace from "../components/CardFaces/Card2/HoverFace";
import Card3Face1 from "../components/CardFaces/Card3/Face1";
import Card3Face2 from "../components/CardFaces/Card3/Face2";
import Card3Face3 from "../components/CardFaces/Card3/Face3";
import Card3HoverFace from "../components/CardFaces/Card3/HoverFace";
import Card4Face1 from "../components/CardFaces/Card4/Face1";
import Card4Face2 from "../components/CardFaces/Card4/Face2";
import Card4HoverFace from "../components/CardFaces/Card4/HoverFace";
import Card5Face1 from "../components/CardFaces/Card5/Face1";
import Card5Face2 from "../components/CardFaces/Card5/Face2";
import Card5Face3 from "../components/CardFaces/Card5/Face3";
import Card5HoverFace from "../components/CardFaces/Card5/HoverFace";
import Card6Face1 from "../components/CardFaces/Card6/Face1";
import Card6Face2 from "../components/CardFaces/Card6/Face2";
import Card6HoverFace from "../components/CardFaces/Card6/HoverFace";
import Card7Face1 from "../components/CardFaces/Card7/Face1";
import Card7Face2 from "../components/CardFaces/Card7/Face2";
import Card7HoverFace from "../components/CardFaces/Card7/HoverFace";
import Card8Face1 from "../components/CardFaces/Card8/Face1";
import Card8Face2 from "../components/CardFaces/Card8/Face2";
import Card8Face3 from "../components/CardFaces/Card8/Face3";
import Card8HoverFace from "../components/CardFaces/Card8/HoverFace";
import Card9Face1 from "../components/CardFaces/Card9/Face1";
import Card9Face2 from "../components/CardFaces/Card9/Face2";
import Card9Face3 from "../components/CardFaces/Card9/Face3";
import Card9HoverFace from "../components/CardFaces/Card9/HoverFace";
import Card10Face1 from "../components/CardFaces/Card10/Face1";
import Card10Face2 from "../components/CardFaces/Card10/Face2";
import Card10Face3 from "../components/CardFaces/Card10/Face3";
import Card10HoverFace from "../components/CardFaces/Card10/HoverFace";
import Card11Face1 from "../components/CardFaces/Card11/Face1";
import Card11Face2 from "../components/CardFaces/Card11/Face2";
import Card11Face3 from "../components/CardFaces/Card11/Face3";
import Card11HoverFace from "../components/CardFaces/Card11/HoverFace";
import Card12Face1 from "../components/CardFaces/Card12/Face1";
import Card12Face2 from "../components/CardFaces/Card12/Face2";
import Card12HoverFace from "../components/CardFaces/Card12/HoverFace";
import Card13Face1 from "../components/CardFaces/Card13/Face1";
import Card13Face2 from "../components/CardFaces/Card13/Face2";
import Card13HoverFace from "../components/CardFaces/Card13/HoverFace";
import Card14Face1 from "../components/CardFaces/Card14/Face1";
import Card14Face2 from "../components/CardFaces/Card14/Face2";
import Card14HoverFace from "../components/CardFaces/Card14/HoverFace";
import Card15Face1 from "../components/CardFaces/Card15/Face1";
import Card15Face2 from "../components/CardFaces/Card15/Face2";
import Card15Face3 from "../components/CardFaces/Card15/Face3";
import Card15HoverFace from "../components/CardFaces/Card15/HoverFace";
import Card16Face1 from "../components/CardFaces/Card16/Face1";
import Card16Face2 from "../components/CardFaces/Card16/Face2";
import Card16HoverFace from "../components/CardFaces/Card16/HoverFace";
import Card17Face1 from "../components/CardFaces/Card17/Face1";
import Card17Face2 from "../components/CardFaces/Card17/Face2";
import Card17HoverFace from "../components/CardFaces/Card17/HoverFace";
import Card18Face1 from "../components/CardFaces/Card18/Face1";
import Card18Face2 from "../components/CardFaces/Card18/Face2";
import Card18HoverFace from "../components/CardFaces/Card18/HoverFace";
import Card19Face1 from "../components/CardFaces/Card19/Face1";
import Card19Face2 from "../components/CardFaces/Card19/Face2";
import Card19HoverFace from "../components/CardFaces/Card19/HoverFace";
import Card20Face1 from "../components/CardFaces/Card20/Face1";
import Card20Face2 from "../components/CardFaces/Card20/Face2";
import Card20Face3 from "../components/CardFaces/Card20/Face3";
import Card20HoverFace from "../components/CardFaces/Card20/HoverFace";
import Card21Face1 from "../components/CardFaces/Card21/Face1";
import Card21Face2 from "../components/CardFaces/Card21/Face2";
import Card21HoverFace from "../components/CardFaces/Card21/HoverFace";
import Card22Face1 from "../components/CardFaces/Card22/Face1";
import Card22Face2 from "../components/CardFaces/Card22/Face2";
import Card22Face3 from "../components/CardFaces/Card22/Face3";
import Card22HoverFace from "../components/CardFaces/Card22/HoverFace";
import Card23Face1 from "../components/CardFaces/Card23/Face1";
import Card23Face2 from "../components/CardFaces/Card23/Face2";
import Card23Face3 from "../components/CardFaces/Card23/Face3";
import Card23HoverFace from "../components/CardFaces/Card23/HoverFace";
import Card24Face1 from "../components/CardFaces/Card24/Face1";
import Card24Face2 from "../components/CardFaces/Card24/Face2";
import Card24HoverFace from "../components/CardFaces/Card24/HoverFace";
import Card25Face1 from "../components/CardFaces/Card25/Face1";
import Card25Face2 from "../components/CardFaces/Card25/Face2";
import Card25HoverFace from "../components/CardFaces/Card25/HoverFace";
import Card26Face1 from "../components/CardFaces/Card26/Face1";
import Card26Face2 from "../components/CardFaces/Card26/Face2";
import Card26Face3 from "../components/CardFaces/Card26/Face3";
import Card26HoverFace from "../components/CardFaces/Card26/HoverFace";
import Card27Face1 from "../components/CardFaces/Card27/Face1";
import Card27Face2 from "../components/CardFaces/Card27/Face2";
import Card27Face3 from "../components/CardFaces/Card27/Face3";
import Card27HoverFace from "../components/CardFaces/Card27/HoverFace";
import Card28Face1 from "../components/CardFaces/Card28/Face1";
import Card28Face2 from "../components/CardFaces/Card28/Face2";
import Card28Face3 from "../components/CardFaces/Card28/Face3";
import Card28HoverFace from "../components/CardFaces/Card28/HoverFace";
import Card29Face1 from "../components/CardFaces/Card29/Face1";
import Card29Face2 from "../components/CardFaces/Card29/Face2";
import Card29HoverFace from "../components/CardFaces/Card29/HoverFace";
import Card30Face1 from "../components/CardFaces/Card30/Face1";
import Card30Face2 from "../components/CardFaces/Card30/Face2";
import Card30Face3 from "../components/CardFaces/Card30/Face3";
import Card30HoverFace from "../components/CardFaces/Card30/HoverFace";
import Card31Face1 from "../components/CardFaces/Card31/Face1";
import Card31Face2 from "../components/CardFaces/Card31/Face2";
import Card31HoverFace from "../components/CardFaces/Card31/HoverFace";
import Card32Face1 from "../components/CardFaces/Card32/Face1";
import Card32Face2 from "../components/CardFaces/Card32/Face2";
import Card32HoverFace from "../components/CardFaces/Card32/HoverFace";
import Card33Face1 from "../components/CardFaces/Card33/Face1";
import Card33Face2 from "../components/CardFaces/Card33/Face2";
import Card33Face3 from "../components/CardFaces/Card33/Face3";
import Card33HoverFace from "../components/CardFaces/Card33/HoverFace";
import Card34Face1 from "../components/CardFaces/Card34/Face1";
import Card34Face2 from "../components/CardFaces/Card34/Face2";
import Card34Face3 from "../components/CardFaces/Card34/Face3";
import Card34HoverFace from "../components/CardFaces/Card34/HoverFace";
import Card35Face1 from "../components/CardFaces/Card35/Face1";
import Card35Face2 from "../components/CardFaces/Card35/Face2";
import Card35HoverFace from "../components/CardFaces/Card35/HoverFace";
import Card36Face1 from "../components/CardFaces/Card36/Face1";
import Card36Face2 from "../components/CardFaces/Card36/Face2";
import Card36HoverFace from "../components/CardFaces/Card36/HoverFace";
import Card37Face1 from "../components/CardFaces/Card37/Face1";
import Card37Face2 from "../components/CardFaces/Card37/Face2";
import Card37Face3 from "../components/CardFaces/Card37/Face3";
import Card37HoverFace from "../components/CardFaces/Card37/HoverFace";
import Card38Face1 from "../components/CardFaces/Card38/Face1";
import Card38Face2 from "../components/CardFaces/Card38/Face2";
import Card38Face3 from "../components/CardFaces/Card38/Face3";
import Card38HoverFace from "../components/CardFaces/Card38/HoverFace";
import Card39Face1 from "../components/CardFaces/Card39/Face1";
import Card39Face2 from "../components/CardFaces/Card39/Face2";
import Card39Face3 from "../components/CardFaces/Card39/Face3";
import Card39HoverFace from "../components/CardFaces/Card39/HoverFace";
import Card40Face1 from "../components/CardFaces/Card40/Face1";
import Card40Face2 from "../components/CardFaces/Card40/Face2";
import Card40Face3 from "../components/CardFaces/Card40/Face3";
import Card40HoverFace from "../components/CardFaces/Card40/HoverFace";
import Card41Face1 from "../components/CardFaces/Card41/Face1";
import Card41Face2 from "../components/CardFaces/Card41/Face2";
import Card41HoverFace from "../components/CardFaces/Card41/HoverFace";
import Card42Face1 from "../components/CardFaces/Card42/Face1";
import Card42Face2 from "../components/CardFaces/Card42/Face2";
import Card42HoverFace from "../components/CardFaces/Card42/HoverFace";
import Card43Face1 from "../components/CardFaces/Card43/Face1";
import Card43Face2 from "../components/CardFaces/Card43/Face2";
import Card43Face3 from "../components/CardFaces/Card43/Face3";
import Card43HoverFace from "../components/CardFaces/Card43/HoverFace";
import Card44Face1 from "../components/CardFaces/Card44/Face1";
import Card44Face2 from "../components/CardFaces/Card44/Face2";
import Card44HoverFace from "../components/CardFaces/Card44/HoverFace";
import Card45Face1 from "../components/CardFaces/Card45/Face1";
import Card45Face2 from "../components/CardFaces/Card45/Face2";
import Card45Face3 from "../components/CardFaces/Card45/Face3";
import Card45HoverFace from "../components/CardFaces/Card45/HoverFace";
import Card46Face1 from "../components/CardFaces/Card46/Face1";
import Card46Face2 from "../components/CardFaces/Card46/Face2";
import Card46Face3 from "../components/CardFaces/Card46/Face3";
import Card46HoverFace from "../components/CardFaces/Card46/HoverFace";
import Card47Face1 from "../components/CardFaces/Card47/Face1";
import Card47Face2 from "../components/CardFaces/Card47/Face2";
import Card47Face3 from "../components/CardFaces/Card47/Face3";
import Card47HoverFace from "../components/CardFaces/Card47/HoverFace";
import Card48Face1 from "../components/CardFaces/Card48/Face1";
import Card48Face2 from "../components/CardFaces/Card48/Face2";
import Card48Face3 from "../components/CardFaces/Card48/Face3";
import Card48HoverFace from "../components/CardFaces/Card48/HoverFace";

const cards = [
  {
    index: 0,
    path: "about-fire",
    title: "About fire",
    pageUrl: "https://mppttrr.com/about-fire",
    imageUrl: "https://mppttrr.com/images/Aboutfire.jpg",
    cardFaces: [<Card1Face1 />, <Card1Face2 />],
    hoverFace: <Card1HoverFace />,
    coordinates: [2.3493027292401507, 48.85381446695013],
    backgroundColor: "green",
  },
  {
    index: 1,
    path: "yes",
    title: "Yes",
    pageUrl: "https://mppttrr.com/yes",
    imageUrl: "https://mppttrr.com/images/Yes-SS.jpg",
    cardFaces: [<Card2Face1 />, <Card2Face2 />],
    hoverFace: <Card2HoverFace />,
    coordinates: [2.3170061095397894, 48.867290993002094],
    backgroundColor: "green",
  },
  {
    index: 2,
    path: "mala-srna",
    title: "Mala srna",
    pageUrl: "https://mppttrr.com/mala-srna",
    imageUrl: "https://mppttrr.com/images/MalasrnaSS.jpg",
    cardFaces: [<Card3Face1 />, <Card3Face2 />, <Card3Face3 />],
    hoverFace: <Card3HoverFace />,
    coordinates: [2.3487984555791437, 48.859931010275645],
    backgroundColor: "red",
  },
  {
    index: 3,
    path: "the-Germ",
    title: "the Germ",
    pageUrl: "https://mppttrr.com/the-Germ",
    imageUrl: "https://mppttrr.com/images/TheGermSS.jpg",
    cardFaces: [<Card4Face1 />, <Card4Face2 />],
    hoverFace: <Card4HoverFace />,
    coordinates: [2.345424976228887, 48.86208794480789],
    backgroundColor: "green",
  },
  {
    index: 4,
    path: "pastinice",
    title: "Paštinice",
    pageUrl: "https://mppttrr.com/pastinice",
    imageUrl: "https://mppttrr.com/images/PastiniceSS.jpg",
    cardFaces: [<Card5Face1 />, <Card5Face2 />, <Card5Face3 />],
    hoverFace: <Card5HoverFace />,
    coordinates: [2.36724374212255, 48.88011675213241],
    backgroundColor: "green",
  },
  {
    index: 5,
    path: "boliboliboli",
    title: "boliboliboli",
    pageUrl: "https://mppttrr.com/boliboliboli",
    imageUrl: "https://mppttrr.com/images/boliboliboliSS.jpg",
    cardFaces: [<Card6Face1 />, <Card6Face2 />],
    hoverFace: <Card6HoverFace />,
    coordinates: [2.3339860949429765, 48.84646629719194],
    backgroundColor: "purple",
  },
  {
    index: 6,
    path: "gccc",
    title: "GCCC",
    pageUrl: "https://mppttrr.com/gccc",
    imageUrl: "https://mppttrr.com/images/GCCCSS.jpg",
    cardFaces: [<Card7Face1 />, <Card7Face2 />],
    hoverFace: <Card7HoverFace />,
    coordinates: [2.344668326154548, 48.884513494206544],
    backgroundColor: "green",
  },
  {
    index: 7,
    path: "5",
    title: "5",
    pageUrl: "https://mppttrr.com/5",
    imageUrl: "https://mppttrr.com/images/petSS.jpg",
    cardFaces: [<Card8Face1 />, <Card8Face2 />, <Card8Face3 />],
    hoverFace: <Card8HoverFace />,
    coordinates: [2.3811961405252307, 48.84345836165963],
    backgroundColor: "red",
  },
  {
    index: 8,
    path: "Kucica",
    title: "Kućica",
    pageUrl: "https://mppttrr.com/Kucica",
    imageUrl: "https://mppttrr.com/images/zatriocajnadanaSS.jpg",
    cardFaces: [<Card9Face1 />, <Card9Face2 />, <Card9Face3 />],
    hoverFace: <Card9HoverFace />,
    coordinates: [2.2960208828524777, 48.85511117620099],
    backgroundColor: "green",
  },
  {
    index: 9,
    path: "Juillet-e",
    title: "Juillet-e",
    pageUrl: "https://mppttrr.com/Juillet-e",
    imageUrl: "https://mppttrr.com/images/JuilleteSS.jpg",
    cardFaces: [<Card10Face1 />, <Card10Face2 />, <Card10Face3 />],
    hoverFace: <Card10HoverFace />,
    coordinates: [2.3791092929877733, 48.83304472158119],
    backgroundColor: "red",
  },
  {
    index: 10,
    path: "Of-Exactitude-in-Being",
    title: "Of Exactitude in Being",
    pageUrl: "https://mppttrr.com/Of-Exactitude-in-Being",
    imageUrl: "https://mppttrr.com/images/OfExactitudeinBeingSS.jpg",
    cardFaces: [<Card11Face1 />, <Card11Face2 />, <Card11Face3 />],
    hoverFace: <Card11HoverFace />,
    coordinates: [2.296933489241195, 48.85651648397608],
    backgroundColor: "red",
  },
  {
    index: 11,
    path: "Simple-Kind-of-Life",
    title: "Simple Kind of Life",
    pageUrl: "https://mppttrr.com/Simple-Kind-of-Life",
    imageUrl: "https://mppttrr.com/images/SimpleKindofLifeSS.jpg",
    cardFaces: [<Card12Face1 />, <Card12Face2 />],
    hoverFace: <Card12HoverFace />,
    coordinates: [2.282380840042328, 48.84841990057342],
    backgroundColor: "green",
  },
  {
    index: 12,
    path: "Slutpositive",
    title: "Slutpositive",
    pageUrl: "https://mppttrr.com/Slutpositive",
    imageUrl: "https://mppttrr.com/images/SlutpositiveSS.jpg",
    cardFaces: [<Card13Face1 />, <Card13Face2 />],
    hoverFace: <Card13HoverFace />,
    coordinates: [2.2671819010412606, 48.85732764668735],
    backgroundColor: "purple",
  },
  {
    index: 13,
    path: "-",
    title: "-",
    pageUrl: "https://mppttrr.com/-",
    imageUrl: "https://mppttrr.com/images/dashSS.jpg",
    cardFaces: [<Card14Face1 />, <Card14Face2 />],
    hoverFace: <Card14HoverFace />,
    coordinates: [2.346346488719352, 48.85735105737995],
    backgroundColor: "green",
  },
  {
    index: 14,
    path: "The-clothes",
    title: "The clothes",
    pageUrl: "https://mppttrr.com/The-clothes",
    imageUrl: "https://mppttrr.com/images/TheclothesSS.jpg",
    cardFaces: [<Card15Face1 />, <Card15Face2 />, <Card15Face3 />],
    hoverFace: <Card15HoverFace />,
    coordinates: [2.3487776937560585, 48.853099301062585],
    backgroundColor: "purple",
  },
  {
    index: 15,
    path: "gymnos",
    title: "gymnos",
    pageUrl: "https://mppttrr.com/gymnos",
    imageUrl: "https://mppttrr.com/images/gymnosSS.jpg",
    cardFaces: [<Card16Face1 />, <Card16Face2 />],
    hoverFace: <Card16HoverFace />,
    coordinates: [2.309436470043643, 48.863054145586254],
    backgroundColor: "green",
  },
  {
    index: 16,
    path: "SFSB",
    title: "SFSB",
    pageUrl: "https://mppttrr.com/SFSB",
    imageUrl: "https://mppttrr.com/images/sfsbSS.jpg",
    cardFaces: [<Card17Face1 />, <Card17Face2 />],
    hoverFace: <Card17HoverFace />,
    coordinates: [2.3704769604146967, 48.89001223699507],
    backgroundColor: "green",
  },
  {
    index: 17,
    path: "O-vodi",
    title: "O vodi",
    pageUrl: "https://mppttrr.com/O-vodi",
    imageUrl: "https://mppttrr.com/images/OvodiSS.jpg",
    cardFaces: [<Card18Face1 />, <Card18Face2 />],
    hoverFace: <Card18HoverFace />,
    coordinates: [2.3438147614381157, 48.86755527419795],
    backgroundColor: "red",
  },
  {
    index: 18,
    path: "Sanisweaty",
    title: "Sanisweaty",
    pageUrl: "https://mppttrr.com/Sanisweaty",
    imageUrl: "https://mppttrr.com/images/SanisweatySS.jpg",
    cardFaces: [<Card19Face1 />, <Card19Face2 />],
    hoverFace: <Card19HoverFace />,
    coordinates: [2.3658311455818994, 48.87030029813639],
    backgroundColor: "green",
  },
  {
    index: 19,
    path: "Utjehe",
    title: "Utjehe",
    pageUrl: "https://mppttrr.com/Utjehe",
    imageUrl: "https://mppttrr.com/images/UtjeheSS.jpg",
    cardFaces: [<Card20Face1 />, <Card20Face2 />, <Card20Face3 />],
    hoverFace: <Card20HoverFace />,
    coordinates: [2.3504682712896887, 48.86025800139119],
    backgroundColor: "red",
  },
  {
    index: 20,
    path: "Summer+",
    title: "Summer+",
    pageUrl: "https://mppttrr.com/Summer+",
    imageUrl: "https://mppttrr.com/images/SummerPlusSS.jpg",
    cardFaces: [<Card21Face1 />, <Card21Face2 />],
    hoverFace: <Card21HoverFace />,
    coordinates: [2.32241292712282, 48.86499967388632],
    backgroundColor: "red",
  },
  {
    index: 21,
    path: "MiuMiu-Kadin",
    title: "MiuMiu Kadin",
    pageUrl: "https://mppttrr.com/MiuMiu-Kadin",
    imageUrl: "https://mppttrr.com/images/MiuMiuKadinSS.jpg",
    cardFaces: [<Card22Face1 />, <Card22Face2 />, <Card22Face3 />],
    hoverFace: <Card22HoverFace />,
    coordinates: [2.3498069315394683, 48.85649782673042],
    backgroundColor: "purple",
  },
  {
    index: 22,
    path: "Something-lightweight",
    title: "Something lightweight",
    pageUrl: "https://mppttrr.com/Something-lightweight",
    imageUrl: "https://mppttrr.com/images/SomethinglightweightSS.jpg",
    cardFaces: [<Card23Face1 />, <Card23Face2 />, <Card23Face3 />],
    hoverFace: <Card23HoverFace />,
    coordinates: [2.2848059634973126, 48.861981478564104],
    backgroundColor: "purple",
  },
  {
    index: 23,
    path: "again",
    title: "again",
    pageUrl: "https://mppttrr.com/again",
    imageUrl: "https://mppttrr.com/images/againSS.jpg",
    cardFaces: [<Card24Face1 />, <Card24Face2 />],
    hoverFace: <Card24HoverFace />,
    coordinates: [2.3485342650595116, 48.868166667330435],
    backgroundColor: "red",
  },
  {
    index: 24,
    path: "Nights-Out",
    title: "Nights Out",
    pageUrl: "https://mppttrr.com/Nights-Out",
    imageUrl: "https://mppttrr.com/images/NightsOutSS.jpg",
    cardFaces: [<Card25Face1 />, <Card25Face2 />],
    hoverFace: <Card25HoverFace />,
    coordinates: [2.3701991418162796, 48.85358908224615],
    backgroundColor: "green",
  },
  {
    index: 25,
    path: "Dobro-Jutro",
    title: "Dobro Jutro",
    pageUrl: "https://mppttrr.com/Dobro-Jutro",
    imageUrl: "https://mppttrr.com/images/DobroJutroSS.jpg",
    cardFaces: [<Card26Face1 />, <Card26Face2 />, <Card26Face3 />],
    hoverFace: <Card26HoverFace />,
    coordinates: [2.445199506961397, 48.83834938078764],
    backgroundColor: "green",
  },
  {
    index: 26,
    path: "Non-ædificandi",
    title: "Non ædificandi",
    pageUrl: "https://mppttrr.com/Non-ædificandi",
    imageUrl: "https://mppttrr.com/images/TheSpidersCanStaySS.jpg",
    cardFaces: [<Card27Face1 />, <Card27Face2 />, <Card27Face3 />],
    hoverFace: <Card27HoverFace />,
    coordinates: [2.341971424519026, 48.90232189491506],
    backgroundColor: "green",
  },
  {
    index: 27,
    path: "mreza",
    title: "Mreža",
    pageUrl: "https://mppttrr.com/mreza",
    imageUrl: "https://mppttrr.com/images/MrezaSS.jpg",
    cardFaces: [<Card28Face1 />, <Card28Face2 />, <Card28Face3 />],
    hoverFace: <Card28HoverFace />,
    coordinates: [2.2993195075856625, 48.87316803463873],
    backgroundColor: "green",
  },
  {
    index: 28,
    path: "OnaGoodday",
    title: "On a Good day",
    pageUrl: "https://mppttrr.com/OnaGoodday",
    imageUrl: "https://mppttrr.com/images/OnaGooddaySS.jpg",
    cardFaces: [<Card29Face1 />, <Card29Face2 />],
    hoverFace: <Card29HoverFace />,
    coordinates: [2.350112538814229, 48.861642578870914],
    backgroundColor: "white",
  },
  {
    index: 29,
    path: "Samo-kratko",
    title: "Samo kratko",
    pageUrl: "https://mppttrr.com/Samo-kratko",
    imageUrl: "https://mppttrr.com/images/SamokratkoSS.jpg",
    cardFaces: [<Card30Face1 />, <Card30Face2 />, <Card30Face3 />],
    hoverFace: <Card30HoverFace />,
    coordinates: [2.3102822829932923, 48.870346255549336],
    backgroundColor: "red",
  },
  {
    index: 30,
    path: "Pegulism",
    title: "Pegulism",
    pageUrl: "https://mppttrr.com/Pegulism",
    imageUrl: "https://mppttrr.com/images/PegulismSS.jpg",
    cardFaces: [<Card31Face1 />, <Card31Face2 />],
    hoverFace: <Card31HoverFace />,
    coordinates: [2.35014761555167, 48.86243430765853],
    backgroundColor: "white",
  },
  {
    index: 31,
    path: "GODDAMMIT",
    title: "Goddammit!",
    pageUrl: "https://mppttrr.com/GODDAMMIT",
    imageUrl: "https://mppttrr.com/images/GoddammitSS.jpg",
    cardFaces: [<Card32Face1 />, <Card32Face2 />],
    hoverFace: <Card32HoverFace />,
    coordinates: [2.370096416303678, 48.87787817028423],
    backgroundColor: "white",
  },
  {
    index: 32,
    path: "5-per-cent",
    title: "5 per cent",
    pageUrl: "https://mppttrr.com/5-per-cent",
    imageUrl: "https://mppttrr.com/images/5postoSS.jpg",
    cardFaces: [<Card33Face1 />, <Card33Face2 />, <Card33Face3 />],
    hoverFace: <Card33HoverFace />,
    coordinates: [2.3492814101670665, 48.85784538403258],
    backgroundColor: "green",
  },
  {
    index: 33,
    path: "Diospyros-Mimi",
    title: "Diospyros Mimi",
    pageUrl: "https://mppttrr.com/Diospyros-Mimi",
    imageUrl: "https://mppttrr.com/images/DiospyrosMimiSS.jpg",
    cardFaces: [<Card34Face1 />, <Card34Face2 />, <Card34Face3 />],
    hoverFace: <Card34HoverFace />,
    coordinates: [2.36389591494067, 48.87247089055],
    backgroundColor: "green",
  },
  {
    index: 34,
    path: "immacolata",
    title: "immacolata",
    pageUrl: "https://mppttrr.com/immacolata",
    imageUrl: "https://mppttrr.com/images/immacolataSS.jpg",
    cardFaces: [<Card35Face1 />, <Card35Face2 />],
    hoverFace: <Card35HoverFace />,
    coordinates: [2.3346016320013963, 48.85268768516257],
    backgroundColor: "purple",
  },
  {
    index: 35,
    path: "Blagdanske-posjete",
    title: "Blagdanske posjete",
    pageUrl: "https://mppttrr.com/Blagdanske-posjete",
    imageUrl: "https://mppttrr.com/images/BlagdanskeposjeteSS.jpg",
    cardFaces: [<Card36Face1 />, <Card36Face2 />],
    hoverFace: <Card36HoverFace />,
    coordinates: [2.342774253768471, 48.90375869355415],
    backgroundColor: "green",
  },
  {
    index: 36,
    path: "Perihelion",
    title: "Perihelion",
    pageUrl: "https://mppttrr.com/Perihelion",
    imageUrl: "https://mppttrr.com/images/PerihelionSS.jpg",
    cardFaces: [<Card37Face1 />, <Card37Face2 />, <Card37Face3 />],
    hoverFace: <Card37HoverFace />,
    coordinates: [2.349618615403344, 48.85829943739816],
    backgroundColor: "green",
  },
  {
    index: 37,
    path: "Radosti",
    title: "Radosti",
    pageUrl: "https://mppttrr.com/Radosti",
    imageUrl: "https://mppttrr.com/images/RadostiSS.jpg",
    cardFaces: [<Card38Face1 />, <Card38Face2 />, <Card38Face3 />],
    hoverFace: <Card38HoverFace />,
    coordinates: [2.3451830911677765, 48.88907225027508],
    backgroundColor: "green",
  },
  {
    index: 38,
    path: "Malapert",
    title: "Malapert",
    pageUrl: "https://mppttrr.com/Malapert",
    imageUrl: "https://mppttrr.com/images/MalapertSS.jpg",
    cardFaces: [<Card39Face1 />, <Card39Face2 />, <Card39Face3 />],
    hoverFace: <Card39HoverFace />,
    coordinates: [2.3311183791872394, 48.88552890327805],
    backgroundColor: "red",
  },
  {
    index: 39,
    path: "La-Pipithèque",
    title: "La Pipithèque",
    pageUrl: "https://mppttrr.com/La-Pipithèque",
    imageUrl: "https://mppttrr.com/images/LaPipithequeSS.jpg",
    cardFaces: [<Card40Face1 />, <Card40Face2 />, <Card40Face3 />],
    hoverFace: <Card40HoverFace />,
    coordinates: [2.3477801625940162, 48.861627676038914],
    backgroundColor: "green",
  },
  {
    index: 40,
    path: "L-kao-Ljubav",
    title: "L kao Ljubav",
    pageUrl: "https://mppttrr.com/L-kao-Ljubav",
    imageUrl: "https://mppttrr.com/images/LkaoLjubavSS.jpg",
    cardFaces: [<Card41Face1 />, <Card41Face2 />],
    hoverFace: <Card41HoverFace />,
    coordinates: [2.353896199992535, 48.85356426334182],
    backgroundColor: "white",
  },
  {
    index: 41,
    path: "The-Music-of-Erich-Zann",
    title: "The Music of Erich Zann",
    pageUrl: "https://mppttrr.com/The-Music-of-Erich-Zann",
    imageUrl: "https://mppttrr.com/images/TheMusicofErichZannSS.jpg",
    cardFaces: [<Card42Face1 />, <Card42Face2 />],
    hoverFace: <Card42HoverFace />,
    coordinates: [2.331813915727132, 48.88396964789482],
    backgroundColor: "purple",
  },
  {
    index: 42,
    path: "Mrs-ex-Nobilis",
    title: "Mrs. ex-Nobilis",
    pageUrl: "https://mppttrr.com/Mrs-ex-Nobilis",
    imageUrl: "https://mppttrr.com/images/MrsexNobilisSS.jpg",
    cardFaces: [<Card43Face1 />, <Card43Face2 />, <Card43Face3 />],
    hoverFace: <Card43HoverFace />,
    coordinates: [2.3444782908414887, 48.885162472173256],
    backgroundColor: "green",
  },
  {
    index: 43,
    path: "Whole-tones-only",
    title: "Whole tones only",
    pageUrl: "https://mppttrr.com/Whole-tones-only",
    imageUrl: "https://mppttrr.com/images/WholetonesonlySS.jpg",
    cardFaces: [<Card44Face1 />, <Card44Face2 />],
    hoverFace: <Card44HoverFace />,
    coordinates: [2.3281509760845815, 48.84690127813144],
    backgroundColor: "red",
  },
  {
    index: 44,
    path: "Fallacy-of-misplaced-concreteness",
    title: "Fallacy of misplaced concreteness",
    pageUrl: "https://mppttrr.com/Fallacy-of-misplaced-concreteness",
    imageUrl: "https://mppttrr.com/images/FallacyofmisplacedconcretenessSS.jpg",
    cardFaces: [<Card45Face1 />, <Card45Face2 />, <Card45Face3 />],
    hoverFace: <Card45HoverFace />,
    coordinates: [2.327615453770176, 48.83626641627615],
    backgroundColor: "red",
  },
  {
    index: 45,
    path: "HELLO-PRINCESS",
    title: "HELLO PRINCESS",
    pageUrl: "https://mppttrr.com/HELLO-PRINCESS",
    imageUrl: "https://mppttrr.com/images/HELLOPRINCESSSS.jpg",
    cardFaces: [<Card46Face1 />, <Card46Face2 />, <Card46Face3 />],
    hoverFace: <Card46HoverFace />,
    coordinates: [2.286379758445618, 48.86218875157536],
    backgroundColor: "purple",
  },
  {
    index: 46,
    path: "Vegetation-mints",
    title: "Vegetation mints",
    pageUrl: "https://mppttrr.com/Vegetation-mints",
    imageUrl: "https://mppttrr.com/images/VegetationmintsSS.jpg",
    cardFaces: [<Card47Face1 />, <Card47Face2 />, <Card47Face3 />],
    hoverFace: <Card47HoverFace />,
    coordinates: [2.31808296569066, 48.86510851699004],
    backgroundColor: "green",
  },
  {
    index: 47,
    path: "All-Models-are-Wrong",
    title: "All Models are Wrong",
    pageUrl: "https://mppttrr.com/All-Models-are-Wrong",
    imageUrl: "https://mppttrr.com/images/AllModelsareWrongSS.jpg",
    cardFaces: [<Card48Face1 />, <Card48Face2 />, <Card48Face3 />],
    hoverFace: <Card48HoverFace />,
    coordinates: [2.3346800301880943, 48.86246287991488],
    backgroundColor: "green",
  },
];

export function getCardFromIndex(index: number) {
  return cards.find((element) => element.index === index);
}

export function getCardFromCardPath(path: string) {
  return cards.find((element) => element.path === path);
}

export function getCardCoordinates() {
  const coordinates = [];

  for (let i = 0; i < cards.length; i++) {
    coordinates.push(cards[i].coordinates);
  }

  return coordinates;
}

export default cards;
