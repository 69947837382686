import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [paddingLeftAndRight, setPaddingLeftAndRight] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.0287 * cardRef.current.clientWidth));
        setLineHeight(0.043 * cardRef.current.clientWidth);
        setLetterSpacing(0.001 * cardRef.current.clientWidth);
        setPaddingLeftAndRight(0.05 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          letterSpacing: `${letterSpacing}px`,
          padding: `0 ${paddingLeftAndRight}px`,
          textAlign: "justify",
        }}
      >
        <p>&nbsp;&nbsp;&nbsp;&nbsp;The belief in eternal change!</p>
        <p>
          And then again, proof of the confluence and circling, the works about
          pretentiousness, which in their own awfulness manage to be the
          embodiment of their fear. Me who judges everything that I can't stop
          hating in myself. How many layers cancel the transparency?
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;Not yet, not now, tulle... a net for petty
          hatreds.
        </p>
      </div>
    </div>
  );
}
