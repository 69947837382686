import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);
  const [paddingLeftAndRight, setPaddingLeftAndRight] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.033 * cardRef.current.clientWidth));
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setLetterSpacing(0.001 * cardRef.current.clientWidth);
        setMarginTop(0.03 * cardRef.current.clientWidth);
        setPaddingLeftAndRight(0.1 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginTop: `${marginTop}px`,
          letterSpacing: `${letterSpacing}px`,
          padding: `0 ${paddingLeftAndRight}px`,
          textAlign: "justify",
        }}
      >
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;It used to be when the summer was ending, that
          my back would stick to the wall of the reservoir. Midday, midnight,
          completely oblivious to the difference, it never made a difference,
          when it started, the not picking up. There I would lick my wounds, all
          the ticks avoiding me.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;It is the same feeling that never went away
          nor furthered in virtue to make a good girl out of me: I miss my home
          forever, the home of love and love of friends, forever being ready for
          all my mistakes. I miss my home forever because its unbearable silence
          makes me seem like the wild one, even though I am just hungry. A
          stupid quirk disguised in a good waist-to- hip ratio. I miss my home
          even staying home, past being gone and my memories becoming too
          desolate. I don't want to be the only prospect in them, I don't want
          them just about me, but I can't recall anything, this being when it
          started, and now when it finishes. I miss my home and fill all the
          empty spots with me at presumed home and cry everyday anywhere with a
          big cold scrapping heavily at the bottom of my lungs, at the back of
          my back. I will never get home again. Is it intolerably hard to hear
          it so many times? Everybody keeps telling me it is still there but I
          know, one hundred percent I know, because I've seen nobody I knew,
          every single time I tried to get home.
        </p>
      </div>
    </div>
  );
}
