import { motion } from "framer-motion";

import styles from "./CardGlare.module.scss";

interface CardGlareProps {
  style: object;
}

export default function CardGlare(props: CardGlareProps) {
  return <motion.div className={styles.cardGlare} style={props.style} />;
}
