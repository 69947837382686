import cx from "classnames";

import styles from "./LoadingScreen.module.scss";

export default function LoadingScreen() {
  return (
    <div className={styles.loading}>
      <div className={cx(styles.drop, styles.first)}>
        <img
          src={`${process.env.PUBLIC_URL}/images/drop.png`}
          alt=""
          className={styles.img}
        />
      </div>
      <div className={cx(styles.drop, styles.second)}>
        <img
          src={`${process.env.PUBLIC_URL}/images/drop.png`}
          alt=""
          className={styles.img}
        />
      </div>
      <div className={cx(styles.drop, styles.third)}>
        <img
          src={`${process.env.PUBLIC_URL}/images/drop.png`}
          alt=""
          className={styles.img}
        />
      </div>
    </div>
  );
}
