import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.032 * cardRef.current.clientWidth));
        setLetterSpacing(0.0025 * cardRef.current.clientWidth);
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setMarginRight(0 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
          textAlign: "center",
        }}
      >
        <p>a place with perpetual winter</p>
        <p>to un-summer this creeper</p>
        <p>unsummon the life</p>
        <p>given to me on a platter</p>
        <p>virtue in trying</p>
        <p>get rid of this marian trigger</p>
        <p>all bailers alike</p>
        <p>beginning to linger</p>
        <p>please stop it i beg of you</p>
        <p>in need of a life raft to differ</p>
        <p>hey hey hey</p>
        <p>only today deeper into</p>
        <p>sorrow covering up the marrow</p>
        <p>unmarried, unkept, unswallowed</p>
        <p>unknown me</p>
        <p>in need to snark at it all</p>
        <p>maybe if we all end up dead</p>
        <p>small things will matter</p>
        <p>stories of the little people</p>
      </div>
    </div>
  );
}
