import {
  motion,
  useAnimationControls,
  useMotionTemplate,
  useSpring,
} from "framer-motion";
import React, { useEffect, useState } from "react";
import cx from "classnames";

import { clamp, round } from "../../helpers/math";
import CardGlare from "./CardGlare/CardGlare";
import CardShine from "./CardShine/CardShine";
import styles from "./MapCard.module.scss";

// values used in the animation logic for devices without a mouse
let prevTime: number | undefined;
let reqAnimationFrameId: number;
let animationAngle: number = 0;

interface MapCardProps {
  onClose: React.MouseEventHandler<HTMLDivElement>;
  cardFaces: JSX.Element[];
  backgroundColor: string;
}

export default function MapCard(props: MapCardProps) {
  // used to set and flip the card faces
  const [angle, setAngle] = useState(0);
  const [currentIndexFront, setCurrentIndexFront] = useState(0);
  const [currentIndexBack, setCurrentIndexBack] = useState(1);
  const [isFront, setIsFront] = useState(true);

  // animation controls used for the card flip animation
  const animationControls = useAnimationControls();

  // springs used for the interaction animation
  const rotateDeltaY = useSpring(0);
  const rotateDeltaX = useSpring(0);

  // springs used for the glare animation
  const glareY = useSpring(0);
  const glareX = useSpring(0);
  const glareOpacity = useSpring(0);

  // springs used for the shine animation
  const shineY = useSpring(0);
  const shineX = useSpring(0);
  const shineOpacity = useSpring(0);
  const shineHyp = useSpring(0);

  // styles used for the interaction
  const transform = useMotionTemplate`rotateY(${rotateDeltaY}deg) rotateX(${rotateDeltaX}deg)`;
  const background = useMotionTemplate`radial-gradient(
      farthest-corner circle at ${glareX}% ${glareY}%,
      rgba(255, 255, 255, 0.3) 10%,
      rgba(255, 255, 255, 0.25) 20%,
      rgba(0, 0, 0, 0.1) 90%)`;

  const opacity = useMotionTemplate`${glareOpacity}`;

  const cardFacesLength = props.cardFaces.length;

  useEffect(() => {
    // From MDN, for `pointer: coarse`:
    // "The primary input mechanism includes a pointing device of limited accuracy, such as a finger on a touchscreen."
    // This means that we want to start animating the card ourselves only if the users' device doesn't have a mouse.
    if (window.matchMedia("(pointer: coarse)").matches) {
      reqAnimationFrameId = window.requestAnimationFrame(handleAnimateCard);

      return () => window.cancelAnimationFrame(reqAnimationFrameId);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    // (we can have more than two faces in the card, so this code takes care of the display logic)
    // set the card face index of the card face currently not shown
    if (isFront) {
      // will turn to back on click, set back index before animating
      setCurrentIndexBack((currentIndexFront + 1) % cardFacesLength);
    } else {
      // will turn to front on click, set front index before animating
      setCurrentIndexFront((currentIndexBack + 1) % cardFacesLength);
    }

    // now the flip animation will be triggered, and the correct face/component will come to the foreground
    animateCardFlipSequence(angle - 180);
    setIsFront((prevIsFront) => !prevIsFront);
    setAngle((prevAngle) => prevAngle - 180);
    // after the flip is completed, the new face x-axis angle needs to change sign to keep the card rotated in the same direction
    rotateDeltaX.set(-1 * rotateDeltaX.get());
  }

  async function animateCardFlipSequence(angle: number) {
    await animationControls.start({
      transform: `rotateY(${angle}deg)`,
      transition: {
        ease: "easeOut",
        duration: 0.04,
      },
    });
    await animationControls.start({
      transform: [
        `rotateY(${angle - 15}deg)`,
        `rotateY(${angle + 5}deg)`,
        `rotateY(${angle}deg)`,
      ],
      transition: { type: "spring", stiffness: 100, damping: 5 },
    });
  }

  function renderCardFront() {
    return props.cardFaces[currentIndexFront];
  }

  function renderCardBack() {
    return props.cardFaces[currentIndexBack];
  }

  function animateInteraction(pointerPositionPercentage: {
    x: number;
    y: number;
  }) {
    // here, the center of the element is {x: 0, y: 0}
    // top left is {x: -50, y: -50}, bottom right is {x: 50, y: 50}
    const pointerPositionPercentageCentered = {
      x: pointerPositionPercentage.x - 50,
      y: pointerPositionPercentage.y - 50,
    };

    // adjust the values to something that we can use as an angle offset
    const adjustedPosition = {
      x: round(-(pointerPositionPercentageCentered.x / 16)),
      y: round(pointerPositionPercentageCentered.y / 16),
    };

    // pointer movement in x-axis makes the card rotate around the y-axis, and vice-versa
    rotateDeltaY.set(adjustedPosition.x);
    // x-axis angle needs to change sign to keep the card rotated in the same direction, depending on which face is shown
    rotateDeltaX.set(isFront ? adjustedPosition.y : -1 * adjustedPosition.y);

    // makes the glare effect follow the pointer
    glareX.set(pointerPositionPercentage.x);
    glareY.set(pointerPositionPercentage.y);
    // makes the glare effect visible
    glareOpacity.set(1);

    // makes the shine effect follow the pointer
    // here, we are also dividing by a certain amount to make the effect look nicer
    shineX.set(pointerPositionPercentage.x / 5);
    shineY.set(pointerPositionPercentage.y / 5);
    // makes the shine effect visible
    shineOpacity.set(1);

    // distance from card center, normalized
    const hyp = clamp(
      Math.sqrt(
        pointerPositionPercentageCentered.y *
          pointerPositionPercentageCentered.y +
          pointerPositionPercentageCentered.x *
            pointerPositionPercentageCentered.x
      ) / 50,
      0,
      1
    );
    shineHyp.set(hyp);
  }

  // this function controls the card animation (card 3d movement, glare and shine effects)
  // when no pointer is available (e.g. on smartphones) through the use of requestAnimationFrame.
  // we are replacing the x and y data (usually coming from the pointer) with our own
  function handleAnimateCard(time: number) {
    if (prevTime) {
      const delta = time - prevTime;

      // the angle in radians, cycles from 0 to 2*Pi
      animationAngle = (animationAngle + delta * 0.002) % (Math.PI * 2);

      const y = Math.sin(animationAngle) * 25;
      const x = -1 * y;

      // In the calculation above, we have assumed that card center is at (0,0)
      // and the extent of x and y values is [-50, 50].
      // This function needs x and y where the top left corner is (0,0),
      // and the extent of x and y values is [0, 100].
      // So, we are transposing both values by 50.
      animateInteraction({ x: x + 50, y: y + 50 });
    }

    prevTime = time;
    reqAnimationFrameId = window.requestAnimationFrame(handleAnimateCard);
  }

  // this function controls the card animation (card 3d movement, glare and shine effects) on pointer move
  function handlePointerMove(e: React.PointerEvent<HTMLDivElement>) {
    const el = e.target as HTMLDivElement;
    const rect = el.getBoundingClientRect();

    // top left is {x: 0, y: 0}, bottom right is {x: width, y: height}
    const pointerPositionPixels = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };

    // same as pointerPositionPixels, expressed as percentage (min value is 0, max value is 100)
    const pointerPositionPercentage = {
      x: clamp(round((100 / rect.width) * pointerPositionPixels.x)),
      y: clamp(round((100 / rect.height) * pointerPositionPixels.y)),
    };

    // calling the function responsible for actually animating the card interaction
    // it can also be called by requestAnimationFrame on touch-based devices
    animateInteraction(pointerPositionPercentage);
  }

  // when the pointer is out of the card area, return the interaction animations to default values
  function handlePointerOut() {
    rotateDeltaY.set(0);
    rotateDeltaX.set(0);
    glareOpacity.set(0);
    shineOpacity.set(0);
    shineHyp.set(0);
  }

  return (
    <div className={styles.flipCardWrapper} onClick={props.onClose}>
      <div
        className={cx(styles.buttonWrapper, {
          [styles.cursorDark]: props.backgroundColor === "red",
        })}
      >
        <motion.button
          className={cx(styles.flipCard, {
            [styles.cursorDark]: props.backgroundColor === "red",
          })}
          onClick={handleClick}
          animate={animationControls}
        >
          <motion.div
            className={styles.interactionWrapper}
            onPointerMove={handlePointerMove}
            onPointerOut={handlePointerOut}
            style={{ transform }}
          >
            <div
              className={cx(styles.flipCardFront, {
                [styles.redBg]: props.backgroundColor === "red",
                [styles.purpleBg]: props.backgroundColor === "purple",
                [styles.whiteBg]: props.backgroundColor === "white",
              })}
            >
              {isFront && (
                <div className={styles.faceWrapper}>
                  {renderCardFront()}
                  <CardShine
                    posX={shineX}
                    posY={shineY}
                    opacity={shineOpacity}
                    shineHyp={shineHyp}
                  />
                  <CardGlare style={{ background, opacity }} />
                </div>
              )}
            </div>
            <div
              className={cx(styles.flipCardBack, {
                [styles.redBg]: props.backgroundColor === "red",
                [styles.purpleBg]: props.backgroundColor === "purple",
                [styles.whiteBg]: props.backgroundColor === "white",
              })}
            >
              {!isFront && (
                <div className={styles.faceWrapper}>
                  {renderCardBack()}
                  <CardShine
                    posX={shineX}
                    posY={shineY}
                    opacity={shineOpacity}
                    shineHyp={shineHyp}
                  />
                  <CardGlare style={{ background, opacity }} />
                </div>
              )}
            </div>
          </motion.div>
        </motion.button>
      </div>
    </div>
  );
}
