import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.032 * cardRef.current.clientWidth));
        setLetterSpacing(0.002 * cardRef.current.clientWidth);
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setMarginLeft(0.5 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>Dakle,</p>
        <p>Kimne glavom djevojka da bi sve imalo smisla:</p>
        <p>Što si bliže Suncu, to si više promrzla.</p>
        <p>Klinka nikad saznala gdje ti središte leži,</p>
        <p>što ti daje vrtoglavice i čemu stremiš.</p>
        <p>Bez vrata, ni pukotine da ju pusti unutra,</p>
        <p>Nikada bilo nije, toliko blizu Sunca.</p>
        <p>Sjaji tako jarko kao da je u inat,</p>
        <p>zatvaraju svi oči jer ne mogu izdržat.</p>
        <p>Ali svoj nevidljivoj blizini usprkos,</p>
        <p>ti si hladna, daleka, ledena božanstvenost.</p>
        <p>Jedan, četiri, sedam, jedan, nula, nula,</p>
        <p>šest, tri, dva, je li Neron postala cura?</p>
        <p>Uvijek kasnimo, pogledaj gdje smo!</p>
        <p>Zima je počela, solsticij prošao!</p>
        <p>A ti i ja za četiri milenija u bezdan,</p>
        <p>volimo jedno drugo na moj rođendan!</p>
        <p>Mi ne pratimo upisane za nas</p>
        <p>orbite vrlo visokih ekscentriciteta,</p>
        <p>Djevojka, Sunce, griješljiv tezaurus,</p>
        <p>Svi iseljeni iz svojih rodnih mjesta.</p>
        <p>Uvijek ćemo imati ljubav mrzlu,</p>
        <p>vruće ružičastu lososa u kretnji,</p>
        <p>plavi kamen ima jagodu za jezgru,</p>
        <p>Nitko ne zna da se led ne tanji.</p>
      </div>
    </div>
  );
}
