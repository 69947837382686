import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.032 * cardRef.current.clientWidth));
        setLetterSpacing(0.002 * cardRef.current.clientWidth);
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setMarginLeft(0.5 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          width: "100%",
        }}
      >
        <p>So,</p>
        <p>A nod from the girl to make all the sense:</p>
        <p>Closer to the Sun you are, the colder you get.</p>
        <p>Kid never found where your center lies,</p>
        <p>what makes your head spin and strive.</p>
        <p>No doors at all, no crack to let her in,</p>
        <p>It is so close to the Sun, to never been.</p>
        <p>Shining so bright feels like in spite</p>
        <p>everybody needs to close their eyes.</p>
        <p>But despite all the invisible proximity,</p>
        <p>you are a cold, distant, freezing divinity.</p>
        <p>One, four, seven, one, zero, zero,</p>
        <p>six, three, two, is the girl the Nero?</p>
        <p>We are always late, look where we are!</p>
        <p>Winter started, solstice passed away!</p>
        <p>And you and me in four millennia far,</p>
        <p>loving each other on my birthday!</p>
        <p>We do not follow the written for us</p>
        <p>orbits with very high eccentricities,</p>
        <p>The girl, the Sun, the faulty thesaurus,</p>
        <p>All evicted from their home cities.</p>
        <p>We will always have a cold love,</p>
        <p>hot pink in salmon swimming,</p>
        <p>blue rock with a strawberry core,</p>
        <p>Nobody knows of ice not thinning.</p>
      </div>
    </div>
  );
}
