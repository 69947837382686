import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.037 * cardRef.current.clientWidth));
        setLineHeight(0.06 * cardRef.current.clientWidth);
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setMarginRight(-0.5 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
          letterSpacing: `${letterSpacing}px`,
          width: "100%",
        }}
      >
        <p>hello princess</p>
        <p>where do you rest?</p>
        <p>if they find your lying place</p>
        <p>in 90 years you've slept alone</p>
        <p>in a ditch, burned through</p>
        <p>with your brother</p>
        <p>will it make sense?</p>
        <p>all the mystics of the world</p>
        <p>are by now proven to be</p>
        <p>just human</p>
        <p>if they were ever lovers or friends</p>
        <p>nothing is what they are worth.</p>
        <p>they knew of your corsets</p>
        <p>filled with jewels!</p>
        <p>and if they uncover</p>
        <p>the cowards of the revolution</p>
        <p>the crime of death will always be</p>
        <p>less than the crime of life.</p>
      </div>
    </div>
  );
}
