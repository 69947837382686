import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.028 * cardRef.current.clientWidth));
        setLineHeight(0.038 * cardRef.current.clientWidth);
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setMarginRight(-0.05 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
          letterSpacing: `${letterSpacing}px`,
        }}
      >
        <p>tell me tell me tell me</p>
        <p>who steals your glances?</p>
        <p>is it a wish, a need,</p>
        <p>an excuse for romance?</p>
        <p>in the area between</p>
        <p>private terror theater</p>
        <p>&amp; being blissfully fifteen,</p>
        <p>how has becoming a realtor</p>
        <p>of my fleshy strengths</p>
        <p>that hold your house,</p>
        <p>making me pay the rent,</p>
        <p>i never made it your spouse?</p>
        <p>tell me sweetie,</p>
        <p>is it SANISWEATY</p>
        <p>the lot where you meet me?</p>
        <p>Correcting the doubts</p>
        <p>obviously eternally,</p>
        <p>making sure the crowds</p>
        <p>chew on it infernally.</p>
        <p>I guessed all the guests right,</p>
        <p>as always,</p>
        <p>and those who grew bright</p>
        <p>were dead in four days.</p>
        <p>But not you SANISWEATY,</p>
        <p>you would never give a euro</p>
        <p>to the girl that needs to grow.</p>
      </div>
    </div>
  );
}
