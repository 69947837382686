import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.025 * cardRef.current.clientWidth));
        setLetterSpacing(0.004 * cardRef.current.clientWidth);
        setLineHeight(0.04 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          textAlign: "center",
        }}
      >
        <p>u brisanju je, svih print screenova</p>
        <p>bacanju u smeće perja, slamki, post-it-a.</p>
        <p>prstena, od piva, izolirki, kupovnih</p>
        <p>stakla, najčešće bez krvi, pogođenih</p>
        <p>kamenja, žileta, papira,</p>
        <p>moj bože, čak i knjiga</p>
        <p>cd-a, ploča, koncertnih karata</p>
        <p>razbijenih poveznica, ukradenih izložaka.</p>
        <p>opskurne mreže, brojevi, pisma</p>
        <p>napitci, palica, da budem čista</p>
        <p>komadi drva, čavli od lijesova,</p>
        <p>staklenke sa srcima bez baterija</p>
        <p>(ali ti Čuvaj ga)</p>
        <p>mrtvo cvijeće, žestina.</p>
        <p>Sve parfemske bočice</p>
        <p>Magnifique također</p>
        <p>lance, pljuvačke, figurice</p>
        <p>fotografije, dio koji spaja sve</p>
        <p>Karte, setove, strijele, kockice</p>
        <p>tugu što mi se u očima ističe</p>
        <p>rječnike vaših govora</p>
        <p>mape, rute naših svijetova</p>
        <p>sve što ste mi ikada dali</p>
        <p>uglavnom praveći se ne znali</p>
        <p>Odbacujem svaku zakletvu</p>
        <p>Sačuvat ću sebe i svu odjeću.</p>
      </div>
    </div>
  );
}
