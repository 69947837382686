import cx from "classnames";
import { useEffect, useState } from "react";

import ScaleLink from "../ScaleLink/ScaleLink";
import styles from "./Credits.module.scss";

export default function Credits() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickEvent(event) {
      if (isOpen && event.target) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickEvent);

    return () => document.removeEventListener("click", handleClickEvent);
  }, [isOpen]);

  function handleButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(true);
  }

  return (
    <>
      {isOpen && <div className={styles.image}></div>}
      {isOpen && <ScaleLink />}
      <div className={cx(styles.creditsWrapper, { [styles.open]: isOpen })}>
        {isOpen ? (
          <div className={styles.credits}>
            <p>
              Idea, concept, design, photography, styling, editing & words by
              Mia Miletić.
            </p>
            <p>Coding by Matej Vitasović.</p>
            <p>Italian translation by Lea Host.</p>
            <p>2023-</p>
            <p>© OpenStreetMap</p>
          </div>
        ) : (
          <button onClick={handleButtonClick} className={styles.openButton}>
            <img
              src={`${process.env.PUBLIC_URL}/images/mapping13a.png`}
              alt=""
              className={styles.img}
            />
          </button>
        )}
      </div>
    </>
  );
}
