import { useEffect, useState, useRef } from "react";

import styles from "./Face3.module.scss";

export default function Face3() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginLeft, setMarginLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.035 * cardRef.current.clientWidth));
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setLineHeight(0.045 * cardRef.current.clientWidth);
        setMarginLeft(0 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginLeft: `${marginLeft}px`,
          textAlign: "center",
        }}
      >
        <p>Something wants me to wake at 4.</p>
        <p>To return to the smell of heaviness,</p>
        <p>rhythmic nuisance and sweeping,</p>
        <p>morning water, stains from the river.</p>
        <p>The thin window that separates me</p>
        <p>truncately and fragilely from the winter,</p>
        <p>which begins before the apples,</p>
        <p>and it doesn't really exist anymore.</p>
        <p>To the snow from the park,</p>
        <p>chasing for crushes.</p>
        <p>Names unknown to my throat.</p>
        <p>Kidney shakes in the time without resistance,</p>
        <p>that we don't cancel the kilometers with bicycles.</p>
        <p>When the deluge occurs,</p>
        <p>across the oldest bridge, to the south,</p>
        <p>where only one fits</p>
        <p>by the wall, while kissing and fishing.</p>
        <p>Shot me three times with glass.</p>
        <p>It's not like years of videos and songs,</p>
        <p>in the square alone is only quiet moss.</p>
        <p>We waited for it to pass, and it all passed,</p>
        <p>only the smell of urine on the heels remains.</p>
        <p>It's far more beautiful, my little village.</p>
        <p>Only in a dream to that silt,</p>
        <p>to that stoneless fertile soil.</p>
      </div>
    </div>
  );
}
