import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.028 * cardRef.current.clientWidth));
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setLineHeight(0.05 * cardRef.current.clientWidth);
        setMarginRight(-0.07 * cardRef.current.clientWidth);
        setMarginTop(0.06 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
          marginTop: `${marginTop}px`,
        }}
      >
        <p>The Hunger of not having enough</p>
        <p>Don't you have it?</p>
        <p>the Desire for more stuff</p>
        <p>Life Ecstatic.</p>
        <p>beautiful Unknown</p>
        <p>Places curious people go</p>
        <p>Daily bliss</p>
        <p>being spoiled by surroundings</p>
        <p>them rubbing off on you</p>
        <p>until you are no longer gilded</p>
        <p>but golden</p>
        <p>alone in that garden</p>
        <p>looked at also</p>
        <p>Forever existing</p>
        <p>for the lasso</p>
        <p>of moving something</p>
        <p>just a little closer</p>
        <p>to the sun.</p>
      </div>
    </div>
  );
}
