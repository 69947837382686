import { useEffect, useState, useRef } from "react";

import styles from "./Face2.module.scss";

export default function Face2() {
  const cardRef = useRef<HTMLDivElement>(null);

  const [fontSize, setFontSize] = useState<number | undefined>(undefined);
  const [letterSpacing, setLetterSpacing] = useState<number | undefined>(
    undefined
  );
  const [lineHeight, setLineHeight] = useState<number | undefined>(undefined);
  const [marginRight, setMarginRight] = useState<number | undefined>(undefined);
  const [marginTop, setMarginTop] = useState<number | undefined>(undefined);
  const [paddingLeftAndRight, setPaddingLeftAndRight] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      if (cardRef.current) {
        setFontSize(Math.floor(0.025 * cardRef.current.clientWidth));
        setLetterSpacing(0.003 * cardRef.current.clientWidth);
        setLineHeight(0.04 * cardRef.current.clientWidth);
        setMarginRight(0.0 * cardRef.current.clientWidth);
        setMarginTop(0.02 * cardRef.current.clientWidth);
        setPaddingLeftAndRight(0.1 * cardRef.current.clientWidth);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div ref={cardRef} className={styles.card}>
      <div
        style={{
          fontSize: `${fontSize}px`,
          letterSpacing: `${letterSpacing}px`,
          lineHeight: `${lineHeight}px`,
          marginRight: `${marginRight}px`,
          marginTop: `${marginTop}px`,
          padding: `0 ${paddingLeftAndRight}px`,
          textAlign: "justify",
        }}
      >
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;Jedna djevojka koju sam davno poznavala zvala
          se isto tako. Ime joj nije naročito pristajalo i gotovo sam sigurna da
          je bilo namijenjeno njenoj mlađoj sestri. Sestrino se prisustvo
          ocrtavalo u apsolutno svakoj lokvi, ispod baš svakih razjapljenih
          ustiju muških. Po mnogočemu se razlikovala od onog što svi neminovno
          prirodno povezujemo s istim zanatom; njen je talent zamijećen u
          indiskretnoj jednostavnosti.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;Zamislite mjesto gdje ste proplivali kako se proteže
          između dvije stijene, nasipano do dubokog, mutno od ljeta. Upravo tamo
          valjali su se valovi, traljavi, prljavi, i lice djevojke šibali. Ljudi
          okolni, isti oni koji su slinili, nisu nikada saznali što im je u
          tijela doneseno. Ono što su u suprotnom zatomili, zaboravili i
          negirali. Džentlmeni. Starija je sestra pokušala, na načine vrlo
          nenametljive, obraz svoje krvi obraniti, govoreći kako pamet mlađe
          šiklja žustrije nego vrulje na Dragoneri. A ja, koliko sam samo večeri
          propustila, umjesto da i sama valjam neke livade pod sobom! Sve kako
          bih pomogla svojoj prijateljici dijeljenjem letaka “Za čuvanje
          legitimnosti” te mlađe sestre u buduće dane, kada joj bude trebao
          netko visok žarulju zamijeniti. Sjećam se, jer je to bio jedan od onih
          krikova koji čovjeku pobjegnu kao resurs na koji nije niti računao,
          kako je originalna prilikom te projekcije na plaži iz dubine svojeg
          poljuljanog identiteta iskonski zatražila izlazak. I kako je mlađa
          bila dovoljno pametna da intuitivno zna kuda joj osjećaji ne prolaze,
          spasila se sama i izašla potpuno čista. Kao neka mačka, u šumu. Mojoj
          prijateljici savjetovali su, zbog njene anksiozne prirode, da u
          trenutcima krize čita Balzaca. Cijeli grad se skupio da joj pokloni
          Ljudsku komediju, uvezenu u bakreno smeđe platno, čisto i mirišljavo.
        </p>
      </div>
    </div>
  );
}
