import { boundingExtent } from "ol/extent";
import Feature from "ol/Feature.js";
import { Point } from "ol/geom.js";
import TileLayer from "ol/layer/Tile.js";
import Vector from "ol/layer/Vector.js";
import Map from "ol/Map.js";
import { fromLonLat } from "ol/proj.js";
import VectorSource from "ol/source/Vector.js";
import XYZ from "ol/source/XYZ.js";
import { Style, Icon } from "ol/style.js";
import View from "ol/View.js";
import { useEffect } from "react";

import { getCardCoordinates } from "../../cards/cards";
import styles from "./Map.module.scss";

const IMAGE_FILE_PATH = `${process.env.PUBLIC_URL}/paristiles/{z}/{x}/{y}.png`;

const MAP_CENTER = [2.3451866842369817, 48.866396138844294];
const EXTENT_SOUTHWEST = [2.183485972737641, 48.825588319630945];
const EXTENT_NORTHEAST = [2.463266461376264, 48.912251502073325];

export default function MapWrapper({
  onMarkerClick,
  onMarkerMouseOver,
  onMarkerMouseOut,
  onLoad,
}) {
  useEffect(() => {
    if (!window.isMapLoaded) {
      initializeMapAndLayer();
      window.isMapLoaded = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addMarkerToMap(markers, markerCoords, markerIndex) {
    const marker = new Feature({
      geometry: new Point(fromLonLat(markerCoords)),
    });

    marker.setId(markerIndex);

    markers.getSource().addFeature(marker);
  }

  async function addMarkersToMap(markers) {
    const markerData = getCardCoordinates();

    for (let i = 0; i < markerData.length; i++) {
      const markerCoords = markerData[i];
      addMarkerToMap(markers, markerCoords, i);
      await new Promise((resolve) =>
        setTimeout(resolve, 150 + Math.floor(Math.random() * 150))
      );
    }
  }

  function animateOnLoad(view, markers) {
    // for narrower devices, like smartphones, the zoom in animation is slower
    // and the zoom out animation stops at a higher zoom
    if (window.matchMedia("(max-width: 768px)").matches) {
      view.animate(
        {
          center: fromLonLat(MAP_CENTER),
          duration: 1500,
          zoom: 14,
        },
        {
          center: fromLonLat(MAP_CENTER),
          duration: 500,
          zoom: 12.8,
        },
        () => {
          addMarkersToMap(markers);
        }
      );
    } else {
      view.animate(
        {
          center: fromLonLat(MAP_CENTER),
          duration: 1500,
          zoom: 14,
        },
        {
          center: fromLonLat(MAP_CENTER),
          duration: 500,
          zoom: 12.5,
        },
        () => {
          addMarkersToMap(markers);
        }
      );
    }
  }

  function handlePointerMove(pixel, markers, map) {
    markers.getFeatures(pixel).then(function (features) {
      const feature = features.length ? features[0] : undefined;

      if (feature) {
        const pixelCoordinates = map.getPixelFromCoordinate(
          feature.getGeometry().getCoordinates()
        );

        const featureID = feature.getId();

        onMarkerMouseOver(featureID, {
          x: pixelCoordinates[0],
          y: pixelCoordinates[1],
        });
      } else {
        onMarkerMouseOut();
      }
    });
  }

  function handlePointerClick(pixel, markers) {
    markers.getFeatures(pixel).then(function (features) {
      const feature = features.length ? features[0] : undefined;

      if (feature) {
        const featureID = feature.getId();

        onMarkerClick(featureID);
      } else {
        onMarkerMouseOut();
      }
    });
  }

  function initializeMapAndLayer() {
    const extent = boundingExtent([
      fromLonLat(EXTENT_SOUTHWEST),
      fromLonLat(EXTENT_NORTHEAST),
    ]);

    const view = new View({
      center: fromLonLat(MAP_CENTER),
      zoom: 9,
      maxZoom: 16,
      minZoom: 9,
      smoothResolutionConstraint: false,
      smoothExtentConstraint: false,
      extent,
      constrainOnlyCenter: true,
      showFullExtent: true,
    });

    const map = new Map({
      target: "map",
      controls: [],
      layers: [
        new TileLayer({
          source: new XYZ({
            url: IMAGE_FILE_PATH,
          }),
        }),
      ],
      view,
    });

    const markers = new Vector({
      minZoom: 10,
      source: new VectorSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0],
          src: "images/drop.png",
          scale: 0.35,
        }),
      }),
    });
    map.addLayer(markers);

    map.once("loadend", function () {
      onLoad();
      animateOnLoad(view, markers);
    });

    map.on("pointermove", function (evt) {
      // about the matchMedia condition:
      // if a user pans the map on mobile and their finger is over the marker, the hover card wil be shown, causing a UI bug
      // (hover cards shouldn't be shown on touch devices)
      // evt.dragging should be able to take care of this case by itself
      // unfortunately, the hover card is visible for a short moment using just evt.dragging, so we also add the matchMedia condition
      if (evt.dragging || window.matchMedia("(pointer: coarse)").matches) {
        onMarkerMouseOut();
        return;
      }

      const pixel = map.getEventPixel(evt.originalEvent);

      // setting the appropriate cursor style when the pointer is over the marker icon
      // the cursor style is set only if there is a feature at the pixel the pointer is currently hovering over
      map.getTargetElement().style.cursor = map.hasFeatureAtPixel(
        map.getEventPixel(evt.originalEvent)
      )
        ? 'url("/images/cursor06a.png") 5.5 0, auto'
        : "";

      handlePointerMove(pixel, markers, map);
    });

    map.on("singleclick", function (evt) {
      if (evt.dragging) {
        return;
      }
      const pixel = map.getEventPixel(evt.originalEvent);

      handlePointerClick(pixel, markers);
    });
  }

  return <div id="map" className={styles.map} />;
}
